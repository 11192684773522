
import { Options, Vue } from 'vue-class-component'
import AdminPosts from '@/components/Posts/AdminPosts/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminPosts
  }
})
export default class PagesView extends Vue {}
