import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class LandsHolds extends APIBaseFunctions {
  static LandsHoldsDataService = class {
    private static LandsHoldsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static async getLandsHolds (extraParameter = '') {
      return http.get('/landsholds' + (extraParameter.length > 0 ? `?${extraParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/landsholds', data)
    }

    static update (id: string, data: any) {
      return http.put(`/landsholds/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/landsholds/${id}`)
    }
  }
}

export default new LandsHolds()
